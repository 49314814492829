export const getFriendlyTag = (tagName) => {
  switch (tagName) {
    case 'bachelorette':
      return 'wieczór panieński';
    case 'people':
      return 'ludzie';
    case 'pregnancy':
      return 'sesja ciążowa';
    default:
      return '';
  }
}
