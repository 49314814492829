/* global PinUtils */
import React from 'react';
import { SocialIcon } from 'react-social-icons';

export const EntryHeader = ({ title, subtitle }) => {
  const shareOnFb = () => {
    window.open(`https://www.facebook.com/sharer.php?u=${encodeURIComponent(window.location.href)}`, 'sharer', 'width=600,height=400');
  }

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?text=Polecam: ${encodeURIComponent(window.location.href)}`, 'sharer', 'width=600,height=400');
  }

  const shareOnPinterest = () => {
    PinUtils.pinAny()
  }

  return (
    <div className="entry-header">
      <div className="left">
        {subtitle && <p className="label">{subtitle}</p>}
        <h1 className="content-title">{title}</h1>
      </div>
      <div className="right">
        <p className="label">Udostępnij</p>
        <div className="socials">
          <SocialIcon
            network="facebook"
            style={{ height: 24, width: 24 }}
            fgColor="#777"
            bgColor="transparent"
            onClick={shareOnFb}
          />
            <SocialIcon
              network="twitter"
              style={{ height: 24, width: 24 }}
              fgColor="#777"
              bgColor="transparent"
              onClick={shareOnTwitter}
            />
            <SocialIcon
              network="pinterest"
              style={{ height: 24, width: 24 }}
              fgColor="#777"
              bgColor="transparent"
              onClick={shareOnPinterest}
            />
        </div>
        </div>
      </div>
  );
};
